import React from "react";
import ProjectCard from "./ProjectCard"
import Footer from "./Footer.js"
import AboutMe from "./AboutMe.js"
import motorouteBanner from "../assets/motoroute-banner.png"
import jumpTakkyBanner from "../assets/jump-takky-banner.png"

function YouTubeShowcase({ title, videoUrl, description }) {
  return (
    <div className="container mx-auto p-6">
      <h2 className="text-3xl font-bold mb-4 text-gray-800">{title}</h2>
      <div className="aspect-w-16 aspect-h-9 mb-6">
      <iframe width="560" height="315" 
          src={videoUrl} 
          title="YouTube video player" 
          frameborder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerpolicy="strict-origin-when-cross-origin" 
          allowfullscreen></iframe>
      </div>
      <p className="text-lg text-gray-700">
        {description}
      </p>
    </div>
  );
}

function Home() {
    return (
        <div>
            <div className="bg-gradient-to-r from-teal-400 to-blue-500">
                <div className="container mx-auto px-4 py-16 text-center">
                    <h1 className="text-5xl font-extrabold text-white mb-2">
                        Luke Elrod
                    </h1>
                    <p className="text-xl text-white">Software Engineer</p>
                </div>
            </div>

            <AboutMe />

            <div className="container mx-auto px-4 py-8">
                <h1 className="text-3xl font-bold mb-8 text-center">Projects</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    <ProjectCard 
                        title="Motoroute" 
                        description="The preferred motorcycle route planner." 
                        img={motorouteBanner}
                        links={[
                            { url: "", text: "In Closed Testing" },
                            { url: "/motoroute/deleteaccount", text: "Delete Account" },
                        ]}
                    />
                    <ProjectCard 
                        title="Jump Takky" 
                        description="Retro arcade-style infinite runner game." 
                        img={jumpTakkyBanner}
                        links={[
                            { url: "https://play.google.com/store/apps/details?id=org.godotengine.jumptakky&hl=en_US", text: "Google Play Listing" },
                        ]}
                    />
                </div>
            </div>

            <YouTubeShowcase title="Godot C++ tutorial" videoUrl="https://www.youtube.com/embed/02KJouOjQ0c?si=K-As35gLeosSY-Zf" description="A tutorial on how to get GDExtension up and running." />

            <Footer />
        </div>
    );
}

export default Home;
